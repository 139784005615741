import React from "react"
import { Router } from "@reach/router"
import Login from "../components/loginPage"
import PrivateRoute from "../components/PrivateRoute"
import { MediaForm } from "@components/MediaForm/MediaForm"
import { AppContext, initialState, reducer } from "../contexts/AppContext"
import { useReducer } from "react"

const ClientRoutes = () => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <Router>
      <Login path="/login" />
      <PrivateRoute path="/media-form" component={MediaForm} />
    </Router>
  )
}

export default ClientRoutes
