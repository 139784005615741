import { navigate } from "gatsby"
import React, { useContext } from "react"
import { UserContext, UserContextProviderValue } from "../contexts/UserContext"

const notAuthenticatedAction = () => {
  navigate("/login")
  return null
}

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { state, dispatch } = useContext<UserContextProviderValue>(UserContext)

  if (!state.isLoggedIn) {
    notAuthenticatedAction()
    return null
  }

  return <Component {...rest} />
}

export default PrivateRoute
