import { useFormik } from "formik"
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import styled from "styled-components"
import { InferType, mixed, number, object, string } from "yup"
import { AppContext, AppContextProviderValue } from "../../contexts/AppContext"
import { useLocalStorage } from "../../hooks/useLocalStorage"
import { useMediaItems } from "../../hooks/useMediaItems"
import {
  MediaQueryResult,
  useQueryMediaTypes,
} from "../../hooks/useQueryMediaTypes"
import { mediaType } from "../../interfaces/MediaType"
import { MediaItemDto } from "../../types/MediaItemDto"
import { MediaItemToSaveDto } from "../../types/MediaItemToSaveDto"
import { isBrowser } from "../../utils/isBrowser"
import { BoxButton } from "../Button/BoxButton"
import Container from "../Container"
import Layout from "../layout"
import Stack from "../Stack/Stack"

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  input,
  textarea,
  label {
    width: 100%;
  }
  input,
  textarea {
    margin-top: var(--space-s);
  }
`

const ErrorText = styled.p`
  color: red;
`

const FormElement = styled.form`
  max-width: 768px;
`

let mediaItemSchema = object({
  name: string().required(),
  description: string(),
  url: string(),
  imageUrl: string(),
  isCompleted: string(),
  comments: string(),
  rating: number(),
  mediaType: mixed()
    .oneOf(Object.entries(mediaType).map((key, _key) => key))
    .required(),
  dateFinished: string(),
  isOnWishList: string().required(),
})

type MediaItemToSave = InferType<typeof mediaItemSchema>

export const MediaForm = () => {
  const [token] = useLocalStorage<string>("ss_token", "")
  const [idOfItemToUpdate, setIdOfItemToUpdate] = useState("")
  const [itemToUpdate, setItemToUpdate] = useState<MediaItemDto | null>(null)
  const mediaTypesAsArray: { key: string; value: string }[] = useMemo(
    () => Object.entries(mediaType).map(([key, value]) => ({ key, value })),
    [mediaType]
  )
  const { state, dispatch } = useContext<AppContextProviderValue>(AppContext)
  const { queryResult, queryMediaItems } = useQueryMediaTypes()
  const { fetchItems } = useMediaItems()
  const [errorText, setErrorText] = useState<string | null>(null)
  const [saveSuccessMessage, setSaveSuccessMessage] = useState<string | null>()
  const [lookupMediaErrorText, setLookupMediaErrorText] = useState<
    string | null
  >(null)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: itemToUpdate ? itemToUpdate.name : "",
      description: itemToUpdate ? itemToUpdate.description : "",
      url: itemToUpdate ? itemToUpdate.url : "",
      imageUrl: itemToUpdate ? itemToUpdate.imageUrl : "",
      isCompleted: itemToUpdate
        ? itemToUpdate.isCompleted
          ? "Yes"
          : "No"
        : "",
      comments: itemToUpdate ? itemToUpdate.comments : "",
      rating: itemToUpdate ? itemToUpdate.rating : "",
      mediaType: itemToUpdate ? itemToUpdate.mediaType : "",
      dateFinished: itemToUpdate ? itemToUpdate.dateFinished : "",
      isOnWishList: itemToUpdate
        ? itemToUpdate.isOnWishList
          ? "Yes"
          : "No"
        : "",
    } as MediaItemToSave,
    validationSchema: mediaItemSchema,
    onSubmit: values => {
      handleSubmit(values)
    },
  })

  const handleSubmit = useCallback(
    async (values: MediaItemToSave) => {
      const valuesSetToBool: {
        [Property in keyof MediaItemToSaveDto]?: MediaItemToSaveDto[Property]
      } = {
        ...values,
        isCompleted: values.isCompleted === "Yes" ? true : false,
        isOnWishList: values.isOnWishList === "Yes" ? true : false,
      }

      const isUpdatingDocument: boolean = !!idOfItemToUpdate
      const url = isUpdatingDocument
        ? `./api/update-media-item-by-id?id=${idOfItemToUpdate}`
        : `./api/add-media-item`

      try {
        setErrorText(null)
        const result = await fetch(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "POST",
          body: JSON.stringify(valuesSetToBool),
        })

        if (!result.ok) {
          throw new Error(result.statusText)
        }

        await fetchItems()

        setSaveSuccessMessage("Saved Successfully")
      } catch (error) {
        console.log(error)
        setSaveSuccessMessage(null)
        setErrorText("Error Saving Item, please try again")
      }
    },
    [idOfItemToUpdate]
  )

  const lookupMediaResults = useCallback(async () => {
    const { mediaType, name } = formik.values
    setLookupMediaErrorText(null)

    if (!name || !mediaType) {
      setLookupMediaErrorText("Name and media type are required")
      return
    }

    try {
      setLookupMediaErrorText(null)
      await queryMediaItems(mediaType, name)
    } catch (error) {
      console.log(error)
      setLookupMediaErrorText("Error looking up the media item")
    }
  }, [formik.values.mediaType, formik.values.name])

  const getIdFromQueryParams = (): string | null => {
    const params = new URL(window.location.toString()).searchParams
    const id = params.get("id")

    return id
  }

  const fetchTheItemToUpdate = async (id: string) => {
    try {
      const response = await fetch(`./api/get-media-item-by-id?id=${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      const itemToUpdate: MediaItemDto = await response.json()

      setItemToUpdate(itemToUpdate)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (isBrowser()) {
      const id = getIdFromQueryParams()

      if (!id) {
        return
      }

      setIdOfItemToUpdate(id)

      if (state.mediaJournal.mediaItems) {
        let itemInState = state.mediaJournal.mediaItems?.find(
          item => item.id === id
        )

        if (itemInState) {
          setItemToUpdate(itemInState)
          return
        }
      }
      fetchTheItemToUpdate(id)
    }
  }, [])

  const handleSetFieldsFromMediaResult = (item: MediaQueryResult) => {
    formik.setValues({
      ...formik.values,
      name: item.name,
      description: item.description,
      url: item.url,
      imageUrl: item.imageUrl,
    })
  }

  return (
    <Layout>
      <Container>
        <FormElement onSubmit={formik.handleSubmit}>
          <Stack topMarginSize={"s"}>
            <InputWrapper>
              <label>
                Name
                <input
                  name="name"
                  id="name"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
              </label>
            </InputWrapper>
            {formik.errors.name && <ErrorText>{formik.errors.name}</ErrorText>}
            <div>
              <fieldset>
                <legend>Media Type</legend>
                {mediaTypesAsArray.map(el => (
                  <label key={el.key}>
                    {el.value}
                    <input
                      name="mediaType"
                      id={`mediaType-${el.key}`}
                      type="radio"
                      onChange={formik.handleChange}
                      checked={formik.values.mediaType === el.key}
                      value={el.key}
                    />
                  </label>
                ))}
              </fieldset>
              {formik.errors.mediaType && (
                <ErrorText>{formik.errors.mediaType}</ErrorText>
              )}
              {queryResult.length > 0 ? (
                queryResult.map(item => (
                  <div key={item.name}>
                    <p>Plot: {item.description}</p>
                    <img src={item.imageUrl}></img>
                    <button
                      type="button"
                      onClick={() => handleSetFieldsFromMediaResult(item)}
                    >
                      Use this result
                    </button>
                  </div>
                ))
              ) : (
                <p>No results</p>
              )}
              <button
                disabled={!formik.values.name || !formik.values.mediaType}
                type="button"
                onClick={lookupMediaResults}
              >
                Search media
              </button>
            </div>
            {lookupMediaErrorText ? <p>{lookupMediaErrorText}</p> : null}
            <InputWrapper>
              <label>
                Description
                <textarea
                  name="description"
                  id="description"
                  onChange={formik.handleChange}
                  value={formik.values.description}
                />
              </label>
            </InputWrapper>
            <InputWrapper>
              <label>
                Url
                <input
                  name="url"
                  id="url"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.url}
                />
              </label>
            </InputWrapper>
            <InputWrapper>
              <label>
                imageUrl
                <input
                  name="imageUrl"
                  id="imageUrl"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.imageUrl}
                />
              </label>
            </InputWrapper>
            <fieldset>
              <legend>Is Complete?</legend>
              <label>
                Yes
                <input
                  name="isCompleted"
                  id="isComplete-yes"
                  type="radio"
                  onChange={formik.handleChange}
                  value="Yes"
                  checked={formik.values.isCompleted === "Yes"}
                />
              </label>
              <label>
                No
                <input
                  name="isCompleted"
                  id="isComplete-no"
                  type="radio"
                  onChange={formik.handleChange}
                  value="No"
                  checked={formik.values.isCompleted === "No"}
                />
              </label>
            </fieldset>
            <fieldset>
              <legend>Is On Wishlist?</legend>
              <label>
                Yes
                <input
                  name="isOnWishList"
                  id="isOnWishList-yes"
                  type="radio"
                  onChange={formik.handleChange}
                  value="Yes"
                  checked={formik.values.isOnWishList === "Yes"}
                />
              </label>
              <label>
                No
                <input
                  name="isOnWishList"
                  id="isOnWishList-no"
                  type="radio"
                  onChange={formik.handleChange}
                  value="No"
                  checked={formik.values.isOnWishList === "No"}
                />
              </label>
            </fieldset>
            {formik.errors.isOnWishList && (
              <ErrorText>{formik.errors.isOnWishList}</ErrorText>
            )}
            <InputWrapper>
              <label>
                Rating
                <input
                  name="rating"
                  id="rating"
                  type="number"
                  max={10}
                  min={0}
                  step={0.1}
                  onChange={formik.handleChange}
                  value={formik.values.rating}
                />
              </label>
            </InputWrapper>
            <InputWrapper>
              <label>
                Date Finished
                <input
                  name="dateFinished"
                  id="date-finished"
                  type="date"
                  onChange={formik.handleChange}
                  value={formik.values.dateFinished}
                />
              </label>
            </InputWrapper>
            <InputWrapper>
              <label>
                Comments
                <textarea
                  name="comments"
                  id="comments"
                  onChange={formik.handleChange}
                  value={formik.values.comments}
                />
              </label>
            </InputWrapper>
            <BoxButton type="submit">Submit</BoxButton>
          </Stack>
          {errorText ? <p style={{ color: "red" }}>{errorText}</p> : null}
          {saveSuccessMessage ? (
            <p style={{ color: "green" }}>{saveSuccessMessage}</p>
          ) : null}
        </FormElement>
      </Container>
    </Layout>
  )
}
