import { navigate } from "@reach/router"
import React, { useContext, useState } from "react"
import { AuthenticateSuccessResponse } from "../../api/authenticate"
import {
  UserContext,
  UserContextProviderValue,
} from "../../contexts/UserContext"
import {
  SET_HAS_LOGIN_ERROR,
  SET_IS_LOGGED_IN,
  SET_IS_LOGGING_IN,
} from "../../contexts/UserContext.consts"
import { useLocalStorage } from "../../hooks/useLocalStorage"
import { isBrowser } from "../../utils/isBrowser"

const directToHomePage = () => {
  isBrowser() && navigate("/")
}

export const LoginForm = () => {
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [errorText, setErrorText] = useState<string>("")
  const { dispatch: dispatchUserAction } = useContext<UserContextProviderValue>(
    UserContext
  )
  const [_tokenInLocalStorage, setTokenInLocalStorage] = useLocalStorage<
    string
  >("ss_token", "")

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    const headers = {
      "Content-Type": "application/json",
    }
    try {
      dispatchUserAction({
        type: SET_IS_LOGGING_IN,
        data: true,
      })

      const result = await fetch("./api/authenticate", {
        method: "POST",
        headers,
        body: JSON.stringify({ email, password }),
      })

      if (result.status !== 200) {
        throw new Error(`${result.status}`)
      }

      const token: AuthenticateSuccessResponse = await result.json()

      setTokenInLocalStorage(token.token)
      dispatchUserAction({
        type: SET_IS_LOGGED_IN,
        data: true,
      })

      directToHomePage()
    } catch (error) {
      dispatchUserAction({
        type: SET_HAS_LOGIN_ERROR,
        data: true,
      })

      if ((error as Error)?.message) {
        if ((error as Error).message === "401") {
          setErrorText("Password or email incorrect")
          return
        }
        if ((error as Error).message === "400") {
          setErrorText("Please try again")
          return
        }
      }
      setErrorText("Error occurred. Please try again.")
      return
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="email-input">Email</label>
      <input
        type="email"
        id="email-input"
        value={email}
        onChange={el => setEmail(el.target.value)}
        name="email"
        required
      />
      <label htmlFor="password-input">Password</label>
      <input
        type="password"
        required
        id="password-input"
        name="password"
        value={password}
        onChange={el => setPassword(el.target.value)}
      />
      <button type="submit">Submit</button>
      {errorText ? <p>{errorText}</p> : null}
    </form>
  )
}
