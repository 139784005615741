import { useEffect, useState } from "react"
import { mediaType } from "../interfaces/MediaType"

type MediaTypeKeys = keyof typeof mediaType

export type MediaQueryResult = {
  name: string
  description: string
  url?: string
  imageUrl: string
}

export type MediaQueryBody = {
  name: string
  type: MediaTypeKeys
}

const getFetchArguments = (
  type: MediaTypeKeys,
  name: string
): { url: string; options: RequestInit } => {
  switch (type) {
    case "Movie": {
      return {
        url: "./api/query-omdb",
        options: {
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            type,
            name,
          }),
        },
      }
    }
    case "TvSeries": {
      return {
        url: "./api/query-omdb",
        options: {
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            type,
            name,
          }),
        },
      }
    }
    case "VideoGame": {
      return {
        url: "./api/query-games-db",
        options: {
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            name,
            type,
          }),
        },
      }
    }
    default: {
      return {
        url: "./api/query-google-knowledge-graph",
        options: {
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            type,
            name,
          }),
        },
      }
    }
  }
}

export const useQueryMediaTypes = (): {
  queryResult: MediaQueryResult[]
  queryMediaItems: (type: MediaTypeKeys, name: string) => Promise<void>
} => {
  const [queryResult, setQueryResult] = useState<MediaQueryResult[]>([])

  const queryMediaItems = async (
    type: keyof typeof mediaType,
    name: string
  ) => {
    setQueryResult([])
    try {
      const fetchArguments = getFetchArguments(type, name)

      const response = await fetch(fetchArguments.url, fetchArguments.options)

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      const data = (await response.json()) as MediaQueryResult[]

      setQueryResult(data)
    } catch (error) {
      throw new Error(error)
    }
  }

  return {
    queryResult,
    queryMediaItems,
  }
}
