import Container from "@components/Container"
import Layout from "@components/layout"
import { LoginForm } from "@components/LoginForm/LoginForm"
import Stack from "@components/Stack/Stack"
import React from "react"

const Login = () => {
  return (
    <Layout>
      <Container>
        <Stack topMarginSize="xxl">
          <h1>Login</h1>
          <LoginForm></LoginForm>
        </Stack>
      </Container>
    </Layout>
  )
}

export default Login
